import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import useStoryblok from "../lib/storyblok"

import Layout from "../components/Layout"
import DynamicComponent from "../components/DynamicComponent"
import { StyledWrapper } from "../components/Layout/styles"

const Page = ({ pageContext, location }) => {
  const story = useStoryblok(pageContext.story, location)

  const components = story.content.body.map(blok => {
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  return (
    <Layout
      pageDescription={story.content.description}
      pageImage={
        story.content.preview_image ? story.content.preview_image.filename : ""
      }
      pageTitle={story.content.title}
    >
      {components}
    </Layout>
  )
}

export default Page
